<template>
    <NoButtonModal :open="open" @close="handleClose">
        <h2>Submit Refund</h2>

        <div>
            <p>Original Member Payment</p>
            <span
                ><strong>{{ originalPayment }}</strong></span
            >
        </div>

        <div>
            <p>Amount Available for Refund</p>
            <span
                ><strong>{{ availableRefund }}</strong></span
            >
        </div>

        <div>
            <label for="refundAmount">Refund Amount to Client</label>
            <CurrencyInput id="refundAmount" :initialValue="0" @currency="handleCurrency" ref="refundOtherAmount" />
        </div>

        <Dropdown
            v-if="showRefundMethod"
            :options="refundMethodList"
            v-model="refundMethod"
            label="Select Refund Method"
        />

        <Input v-if="refundMethod === 'CHECK'" id="refundCheckNumber" label="Enter Check #" v-model="checkNumber" />

        <Input v-if="refundMethod === 'OTHER'" id="refundOtherDescription" label="Details" v-model="details" />

        <div class="align-right top-20">
            <button @click="handleClose" class="secondary">Cancel</button>
            <button @click="handleSubmitRefund" class="primary">Submit</button>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { payments } from '@/util/apiRequests';
    export default {
        name: 'RefundModal',
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            clientId: {
                type: [String, Number],
                required: true,
            },
            paymentInfo: {
                type: Object,
                required: true,
            },
        },
        components: { CurrencyInput, NoButtonModal },
        data() {
            return {
                refundAmount: 0,
                refundMethodList: [
                    { text: 'Cash', value: 'CASH' },
                    { text: 'Check', value: 'CHECK' },
                    { text: 'Other', value: 'OTHER' },
                ],
                refundMethod: 'CASH',
                checkNumber: '',
                details: '',
            };
        },
        computed: {
            showRefundMethod() {
                return this.paymentInfo.paymentType !== 'CARD' && this.paymentInfo.availableForRefund > 0;
            },
            originalPayment() {
                return this.$getCurrency(parseInt(this.paymentInfo.initialPaymentAmount));
            },
            availableRefund() {
                return this.$getCurrency(parseInt(this.paymentInfo.availableForRefund));
            },
        },
        methods: {
            async handleSubmitRefund() {
                if (this.refundMethod === 'CHECK' && !this.checkNumber) {
                    this.$toasted.error('Check Number is Required');
                    return;
                }

                if (this.refundMethod === 'OTHER' && !this.details) {
                    this.$toasted.error('Description is Required');
                    return;
                }

                if (this.refundAmount === 0) {
                    this.$toasted.error('Refund amount must be more than $0');
                    return;
                }

                if (this.refundAmount > this.paymentInfo.availableForRefund) {
                    this.$toasted.error('Refund amount cannot exceed amount available for refund');
                    return;
                }

                try {
                    const res = await this.$api.post(payments.refund(), {
                        payment_id: this.paymentInfo.paymentId,
                        amount: this.refundAmount,
                        payment_type: this.paymentInfo.paymentType,
                        stripe_payment_intent_id: this.paymentInfo.paymentIntentId || undefined,
                        refund_type: this.refundMethod,
                        client_id: this.clientId,
                    });

                    if (res.status >= 200 && res.status < 300) {
                        this.$toasted.success('Refund successful');
                        this.handleClose();
                    } else {
                        this.$toasted.error('Failed to issue refund');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to issue refund');
                }
            },
            handleCurrency(val) {
                this.refundAmount = this.$getNumFromCurrency(val);
            },
            handleClose() {
                this.$refs.refundOtherAmount.clear();
                this.refundAmount = 0;
                this.refundMethod = 'CASH';
                this.checkNumber = '';
                this.details = '';

                this.$emit('close');
            },
        },
        watch: {
            open() {
                this.refundMethod = this.paymentInfo.paymentType;
            },
        },
    };
</script>

<style scoped></style>
